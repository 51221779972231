import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../locales/messages';
import { Tag, NumberInput } from 'carbon-components-react';
import './SaaSParameters.scss';
import { useRef, MutableRefObject, useState, useEffect } from 'react';
import {
  defaultPremiumLicenses,
  defaultStandardLicenses,
  maxLicenses,
  minLicenses,
} from '../constants/defaults';
import { segmentTracking } from '../lib/tracker';

interface Message {
  id: string;
  defaultMessage: string;
}

export interface SaaSParametersProps {
  premiumLicenses: number;
  standardLicenses: number;
  setStandardLicenses: React.Dispatch<React.SetStateAction<number>>;
  setPremiumLicenses: React.Dispatch<React.SetStateAction<number>>;
}
export interface ParameterProps {
  ParameterTitle: Message;
  ParameterSubtitle: Message;
  Features: string[];
  InputName: string;
  defaultValueInput: number;
  setInputNumber: React.Dispatch<React.SetStateAction<number>> | null;
  maxValue: number;
}
const Parameter = ({
  ParameterTitle,
  ParameterSubtitle,
  Features,
  InputName,
  defaultValueInput,
  setInputNumber,
  maxValue,
}: ParameterProps) => {
  const intl = useIntl();
  const userRef = useRef() as MutableRefObject<HTMLInputElement>;
  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
          <p className="lineTitle">
            <FormattedMessage {...ParameterTitle} />
          </p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
          <p className="lineSubtitle">
            <FormattedMessage {...ParameterSubtitle} />
          </p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 featuresTags">
          <div>
            {Features.map((item: string, index: number) => (
              <Tag
                key={index}
                type={'purple'}
                size="md"
                disabled={false}
                filter={false}
              >
                {item}
              </Tag>
            ))}
          </div>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-4 bx--col-md-2 bx--col-2">
          {InputName.length > 0 ? (
            <NumberInput
              id={InputName}
              ref={userRef}
              value={defaultValueInput}
              min={minLicenses}
              max={maxValue}
              className="NumberInput"
              invalidText={
                messages.Saas_license_error.defaultMessage + maxValue
              }
              onChange={() => {
                if (setInputNumber) {
                  setInputNumber(Number(userRef.current.value));
                }
                segmentTracking('CTA Clicked', {
                  pageTitle: 'Cognos Analytics Price Estimator page',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: ParameterTitle.defaultMessage,
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

const SaaSParameters = ({
  premiumLicenses,
  standardLicenses,
  setPremiumLicenses,
  setStandardLicenses,
}: SaaSParametersProps) => {
  const intl = useIntl();

  const SaaSFeaturesStd = [
    intl.formatMessage(messages.Saas_feature_1),
    intl.formatMessage(messages.Saas_feature_2),
    intl.formatMessage(messages.Saas_feature_3),
    intl.formatMessage(messages.Saas_feature_4),
    intl.formatMessage(messages.Saas_feature_5),
    intl.formatMessage(messages.Saas_feature_6),
  ];

  const SaaSFeaturesPrm = [
    intl.formatMessage(messages.Saas_prem_feature_1),
    intl.formatMessage(messages.Saas_prem_feature_2),
    intl.formatMessage(messages.Saas_prem_feature_3),
  ];

  const [maxStandard, setMaxStandard] = useState(140);
  const [maxPremium, setMaxPremium] = useState(140);

  useEffect(() => {
    setMaxStandard(maxLicenses - premiumLicenses);
    setMaxPremium(maxLicenses - standardLicenses);
  }, [premiumLicenses, standardLicenses]);

  return (
    <>
      <Parameter
        ParameterTitle={messages.Saas_line_1}
        ParameterSubtitle={messages.Saas_line_2}
        Features={SaaSFeaturesStd}
        InputName="field_1"
        defaultValueInput={defaultStandardLicenses}
        setInputNumber={setStandardLicenses}
        maxValue={maxStandard}
      />
      <Parameter
        ParameterTitle={messages.Saas_prem_line_1}
        ParameterSubtitle={messages.Saas_prem_line_2}
        Features={SaaSFeaturesPrm}
        InputName="field_2"
        defaultValueInput={defaultPremiumLicenses}
        setInputNumber={setPremiumLicenses}
        maxValue={maxPremium}
      />
    </>
  );
};

export { SaaSParameters, Parameter };
