import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import messages from '../locales/messages';
import 'src/common/style/common.scss';
import './CognosPriceEstimator.scss';
import Helmet from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Parameter, SaaSParameters } from './SaaSParameters';
import ResultTable from './TableResult';
import { RadioTile, TileGroup } from 'carbon-components-react';
import { setPrice } from '../hooks/usePrice';
import {
  defaultPremiumLicenses,
  defaultStandardLicenses,
  UT30PA,
  defaultOption,
} from '../constants/defaults';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { useCurrency } from 'src/common/hooks/widgets/useCurrency';
import countryList from '../constants/currencies.json';
import { useLang } from 'src/common/hooks/widgets/useLang';
import Thanks from './Thanks';
import { useBPName } from 'src/common/hooks/widgets/useBPName';
import { FormModal } from 'src/common/utils/FormModal';
import * as URX_FORM from '../constants/urx-form';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import { segmentTracking } from '../lib/tracker';
import Footnotes from './Footnote';

export interface CognosPriceEstimatorProps {
  langCode: string;
  test: boolean;
  countryToTest: string;
}

{
  /* Header section */
}
export const WidgetHeader = () => {
  return (
    <>
      <div className="bx--row rowSubHeading">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm=4">
          <p className="subHeading">
            <FormattedMessage {...messages.Subtitle} />
          </p>
        </div>
      </div>
    </>
  );
};
{
  /*  Radios button sections */
}
interface RadioButtonsProps {
  enableRTile1: boolean;
  enableRTile2: boolean;
  enableRTile3: boolean;
  setSwitchTab: Dispatch<SetStateAction<number>>;
  setRadioSelected: Dispatch<SetStateAction<string>>;
  setEnableRTile1: Dispatch<SetStateAction<boolean>>;
  setEnableRTile2: Dispatch<SetStateAction<boolean>>;
  setEnableRTile3: Dispatch<SetStateAction<boolean>>;
  setSwitcherSelected: React.Dispatch<React.SetStateAction<string>>;
}
const RadioButtons = ({
  enableRTile1,
  enableRTile2,
  enableRTile3,
  setSwitchTab,
  setRadioSelected,
  setEnableRTile1,
  setEnableRTile2,
  setEnableRTile3,
  setSwitcherSelected,
}: RadioButtonsProps) => {
  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm=4">
          <TileGroup name="radio tile group" className="tilegroup">
            <RadioTile
              id="option1"
              tabIndex={1}
              value="Cloud On-demand"
              //className="cognos_analytics__tile"
              className="tileOption"
              onClick={() => {
                setRadioSelected(messages.Radio_saas.defaultMessage);
                setSwitchTab(0);
                setEnableRTile1(true);
                setEnableRTile2(false);
                setEnableRTile3(false);
                setSwitcherSelected(messages.Switcher_1.defaultMessage);
                segmentTracking('CTA Clicked', {
                  pageTitle: 'Cognos Analytics Price Estimator page',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: `${messages.Radio_saas.defaultMessage} Tile`,
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
              }}
            >
              <p
                className={
                  enableRTile1 || enableRTile2
                    ? 'RadioTitle blackTone'
                    : 'RadioTitle greyTone'
                }
              >
                <FormattedMessage {...messages.Radio_saas} />
              </p>
              <p
                className={
                  enableRTile1 || enableRTile2
                    ? 'RadioBody blackTone'
                    : 'RadioBody greyTone'
                }
              >
                <FormattedMessage {...messages.Radio_saas_paragraph} />
              </p>
            </RadioTile>
            <RadioTile
              id="option2"
              tabIndex={2}
              value="Cloud"
              className="tileOption"
              onClick={() => {
                setRadioSelected(messages.Radio_cloud.defaultMessage);
                setEnableRTile1(false);
                setEnableRTile2(true);
                setEnableRTile3(false);
                setSwitchTab(0);
                setSwitcherSelected(messages.Switcher_1.defaultMessage);
                segmentTracking('CTA Clicked', {
                  pageTitle: 'Cognos Analytics Price Estimator page',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: `${messages.Radio_cloud.defaultMessage} Tile`,
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
              }}
            >
              <p
                className={
                  enableRTile2 || enableRTile1
                    ? 'RadioTitle blackTone'
                    : 'RadioTitle greyTone'
                }
              >
                <FormattedMessage {...messages.Radio_cloud} />
              </p>
              <p
                className={
                  enableRTile2 || enableRTile1
                    ? 'RadioBody blackTone'
                    : 'RadioBody greyTone'
                }
              >
                <FormattedMessage {...messages.Radio_cloud_paragraph} />
              </p>
            </RadioTile>
            <RadioTile
              id="option3"
              tabIndex={3}
              value="Software"
              className={enableRTile3 ? 'tileOption' : 'tileOption greyTone'}
              onClick={() => {
                setRadioSelected(messages.Radio_software.defaultMessage);
                setEnableRTile1(false);
                setEnableRTile2(false);
                setEnableRTile3(true);
                setSwitcherSelected(messages.Switcher_2.defaultMessage);
                setSwitchTab(1);
                segmentTracking('CTA Clicked', {
                  pageTitle: 'Cognos Analytics Price Estimator page',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: `${messages.Radio_software.defaultMessage} Tile`,
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
              }}
            >
              <p
                className={
                  enableRTile3 ? 'RadioTitle blackTone' : 'RadioTitle greyTone'
                }
              >
                <FormattedMessage {...messages.Radio_software} />
              </p>
              <p
                className={
                  enableRTile3 ? 'RadioBody blackTone' : 'RadioBody greyTone'
                }
              >
                <FormattedMessage {...messages.Radio_software_paragraph} />
              </p>
            </RadioTile>
          </TileGroup>
        </div>
      </div>
    </>
  );
};

const CognosPriceEstimator = ({
  langCode,
  test,
  countryToTest,
}: CognosPriceEstimatorProps) => {
  const [switcherSelected, setSwitcherSelected] = useState(
    messages.Switcher_1.defaultMessage,
  );
  const [switchTab, setSwitchTab] = useState(0);
  const [enableRTile1, setEnableRTile1] = useState(true);
  const [enableRTile2, setEnableRTile2] = useState(false);
  const [enableRTile3, setEnableRTile3] = useState(false);
  const [radioSelected, setRadioSelected] = useState('');
  const [standardLicenses, setStandardLicenses] = useState(
    defaultStandardLicenses,
  );
  const [premiumLicenses, setPremiumLicenses] = useState(
    defaultPremiumLicenses,
  );

  const [currency, setCurrency] = useState('');
  const [country, setCountry] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const [environment] = useEnv();
  // For local testing purpose, set test to true
  const [lang, userLocale] = useLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry,
    localeCode,
  );

  const [saaSPrice, setSaaSPrice] = useCurrency(currency, langCode, 0);
  const [saaSYearlyPrice, setSaaSYearlyPrice] = useCurrency(
    currency,
    langCode,
    0,
  );
  const [saaSStdPrice, setSaaSStdPrice] = useCurrency(currency, langCode, 0);
  const [saaSPrmPrice, setSaaSPrmPrice] = useCurrency(currency, langCode, 0);

  const intl = useIntl();
  const CloudFeatures = [
    intl.formatMessage(messages.Cloud_feature_1),
    intl.formatMessage(messages.Cloud_feature_2),
    intl.formatMessage(messages.Cloud_feature_3),
    intl.formatMessage(messages.Cloud_feature_4),
  ];

  const SoftwareFeatures = [
    intl.formatMessage(messages.Software_feature_1),
    intl.formatMessage(messages.Software_feature_2),
    intl.formatMessage(messages.Software_feature_3),
    intl.formatMessage(messages.Software_feature_4),
    intl.formatMessage(messages.Software_feature_5),
    intl.formatMessage(messages.Software_feature_6),
    intl.formatMessage(messages.Software_feature_7),
    intl.formatMessage(messages.Software_feature_8),
    intl.formatMessage(messages.Software_feature_9),
    intl.formatMessage(messages.Software_feature_10),
    intl.formatMessage(messages.Software_feature_11),
  ];

  /**
   * Call to start the pricing
   */
  setPrice(
    standardLicenses,
    premiumLicenses,
    setSaaSPrice,
    setSaaSYearlyPrice,
    setSaaSStdPrice,
    setSaaSPrmPrice,
    'SaaS',
    currency,
    country,
    localeCode,
    true,
  );

  useEffect(() => {
    const radio = document.getElementById(defaultOption);
    radio?.click();
  }, []);

  const { bpName, logo, companyId, desc } = useBPName(UT30PA, true);

  window.urxEnvironment = environment;
  window.onUrxFormSubmitSuccess = () => {
    setFormComplete(!formComplete);
    setDrawerOpen(false); // Hide Drawer on Thank you page
  };

  return (
    <div className="cognos_analytics widget-styles">
      <Helmet>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/loader.js`}
        ></script>
        <script
          type="application/javascript"
          src={`https://www.ibm.com/account/ibmidutil/widget/js/main.js`}
        ></script>
      </Helmet>
      {formComplete ? (
        <Thanks
          preference={switcherSelected}
          kindService={radioSelected}
          stdLiceses={standardLicenses}
          preLicenses={premiumLicenses}
          monthlyCost={`${saaSPrice}`}
          bpName={bpName}
        />
      ) : (
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm=4">
              <RadioButtons
                enableRTile1={enableRTile1}
                enableRTile2={enableRTile2}
                enableRTile3={enableRTile3}
                setSwitchTab={setSwitchTab}
                setRadioSelected={setRadioSelected}
                setEnableRTile1={setEnableRTile1}
                setEnableRTile2={setEnableRTile2}
                setEnableRTile3={setEnableRTile3}
                setSwitcherSelected={setSwitcherSelected}
              />
            </div>
          </div>
          <div className="bx--row secondRow">
            <div className="bx--col-lg-11 bx--col-md-5 bx--col-sm=4">
              {radioSelected === 'Cloud On-demand' ? (
                <SaaSParameters
                  premiumLicenses={premiumLicenses}
                  standardLicenses={standardLicenses}
                  setPremiumLicenses={setPremiumLicenses}
                  setStandardLicenses={setStandardLicenses}
                />
              ) : radioSelected === messages.Radio_cloud.defaultMessage ? (
                <Parameter
                  ParameterTitle={messages.Cloud_line_1}
                  ParameterSubtitle={messages.Cloud_line_2}
                  Features={CloudFeatures}
                  InputName=""
                  defaultValueInput={0}
                  setInputNumber={null}
                  maxValue={0}
                />
              ) : (
                <Parameter
                  ParameterTitle={messages.Software_line_1}
                  ParameterSubtitle={messages.Software_line_2}
                  Features={SoftwareFeatures}
                  InputName=""
                  defaultValueInput={0}
                  setInputNumber={null}
                  maxValue={0}
                />
              )}
            </div>
            <div className="bx--col-lg-5 bx--col-md-3 bx--col-sm=4">
              <ResultTable
                radioSelected={radioSelected}
                cloudStandarPrice={saaSStdPrice}
                cloudPremiumPrice={saaSPrmPrice}
                monthlyCost={saaSPrice}
                yearlyCost={saaSYearlyPrice}
                standardLincenses={standardLicenses}
                premiumLicenses={premiumLicenses}
                preference={switcherSelected}
                setDrawerOpen={setDrawerOpen}
                langCode={langCode}
                companyId={companyId}
              />
            </div>
            <div className="bx--row">
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sd-4">
                <div className="footIndex">
                  <Footnotes numNote={1} />
                </div>
                <footer className="footNotes">
                  <FormattedMessage {...messages.Saas_footnote} />
                </footer>
              </div>
            </div>
          </div>
        </div>
      )}
      <FormModal
        pageTitle="Cognos Price Estimator Page"
        object="Cogons Price Estimator Page"
        productTitle={'Cogos Price Estimator'}
        pageUrl={''}
        location={'Estimator'}
        bpName={bpName}
        logo={logo}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        formId={URX_FORM.FORM_ID}
        lang={lang}
      ></FormModal>
    </div>
  );
};

export default CognosPriceEstimator;
